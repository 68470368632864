.ProductCartController {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ProductCartController span {
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
}

.AddButton,
.RemoveButton {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}

.RemoveButton img,
.AddButton img {
  width: 100%;
  height: 100%;
}