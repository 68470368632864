.ProductCategory {
  display: flex;
  flex-direction: column;
}

.CategoryTitle{
  font-family: 'Hello Almeida';
  font-size: 45px;
  color: white;
  margin-bottom: 12px;
}

.Products {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}


.ProductCardContainer {
  width: calc(50% - 10px);
  margin: 5px;
  display: flex;
  justify-content: center;
}