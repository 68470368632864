.ProductDetailsContainer {
  margin: 20px;
  max-width: 720px;
  position: relative;
  border-radius: 20px;
  width: calc(100% - 32px);
  max-height: calc(100vh - 32px);
  box-shadow: 0 0 2 0px 5px #000;
}

.ScrollableContainer {
  overflow-y: auto;
  border-radius: 20px;
  max-height: calc(100vh - 32px);
}

.ProductDetailsImages {
  aspect-ratio: 7/6;
  object-fit: cover;
}

.ModalArrow {
  top: 44% !important;
}

.ModalArrowLeft {
  left: 15px;
}

.ModalArrowRight {
  margin: 0px -20px;
}

.CloseButton {
  top: -14px;
  z-index: 10;
  width: 28px;
  right: -14px;
  position: absolute;
}

.ProductDetails {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.ProductDetails .Title {
  margin-bottom: 5px;
  color: #222;
}

.ProductDetails .Price {
  margin-bottom: 10px;
  color: #333;
}

.ProductDetailsCart {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ProductDetailsCart>h3 {
  margin: 30px 0 0 0;
}

.CartControllerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ConfirmButton {
  font-family: "Google Sans", sans-serif;
  color: white;
  margin: 40px 0 20px 0;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 30px;
  border-style: none;
  background-color: #37646b;
}

.Paper {
  width: 100%;
  border: none;
  flex-direction: column;
  justify-content: flex-start;
}

@media all and (max-width: 768px) {
  .ProductDetailsContainer {
    max-width: 400px;
  }
}

@media all and (max-height: 480px) {
  .ProductDetailsContainer {
    max-width: 500px;
  }
}

/* Landscape tablet */
@media all and (max-height: 800px) and (max-width: 1024px) {
  .ProductDetailsContainer {
    max-width: 400px;
  }
}

/* Landsscape smartphone */
@media all and (max-height: 480px) and (max-width: 1024px) {
  .ProductDetailsContainer {
    max-width: 300px;
  }
}