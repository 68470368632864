.Background {
  top: 0;
  left: 0;
  z-index: 1;
  width: 200%;
  height: 200%;
  position: fixed;
  transition: none;
  background-size: contain;
  background-image: url('/public/assets/images/background.png');
}