.Badge {
  top: 10px;
  z-index: 10;
  right: 20px;
  font-size: 80%;
  padding: 0 10px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  border-radius: 10px;
  background-color: white;
}