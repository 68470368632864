.AboutContainer {
    display: flex;
    justify-content: center;
}

.TitleAbout {
    font-family: "Hello Almeida";
    color: #37646b;
    text-align: center;
    font-size: 45px;
    margin: auto;
    padding-top: 20px;
}

.ThumbVideos {
    border: 3px solid black;
    width: 300px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 15px;
    border-radius: 20px;
}

.AboutContent {
    margin: 20px;
}

.AboutContent > h3 {
    font-family: "Google Sans", sans-serif;
    color: #255760;
}