.Header {
  margin-bottom: 60px;
}

.Header nav {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 27px;
  color: white;
  padding: 5px;
}

.Header nav a {
  color: white;
  text-decoration: none;
  font-family: 'Hello Almeida';
  margin-top: 5px;
}

.Logo {
  height: 60px;
  display: flex;
  position: relative;
  justify-content: center;
  background-size: 100% 100%;
  background-repeat: repeat-x;
  background-image: url('/public/assets/images/wave-logo.png');
}

.Logo img {
  width: 150px;
  position: absolute;
  aspect-ratio: 451/301;
  z-index: 2;
}

.SelectedMenu {
  color: #4DB7BB;
}