.IdentificationFormContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 320px;
}

.IdentificationForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Title{
  font-family: "Hello Almeida";
  font-size: 40px;
  color: #37646b;
  margin-bottom: 12px;
}

.TextField {
  margin: 15px 0;
}

.Close {
  position: absolute;
  top: 0;
  right: 0;
}

.SubmitButton {
  color: white;
  background-color: #37646b;
  border-radius: 30px;
  border-style: none;
  padding: 5px 10px;
  font-size: 18px;
  margin: 40px 0 20px 0;
  font-family: "Google Sans", sans-serif;
}