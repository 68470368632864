h3 {
  margin: 0;
}


.CartItem {
  display: flex;
  font-size: 12px;
  padding: 15px 0;
  justify-content: space-evenly;
  align-items: center;
}

.CartItem>img {
  width: 80px;
}

.CartItem:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.CartItemDetails {
  width: 50%;
}

.Controller {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Trash img {
  width: 100%;
  height: 100%;
}


.Trash {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}