.Arrow {
  left: 30px;
}

.Arrow,
.Arrow.ArrowRight {
  top: 4px;
  position: absolute;
  z-index: 10;
  width: 20px;
}

.Arrow img {
  width: 100%
}

.Arrow.ArrowRight {
  left: calc(100vw - 55px);
}