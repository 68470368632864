.CloseButton {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10;
  width: 30px;
  height: 30px;
  display: flex;
  font-family: sans-serif;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #37646b;
  vertical-align: center;
  text-align: center;
}

.CloseButton>span {
  font-size: 22px;
  color: white;
}