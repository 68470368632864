.ContactContainer {
    display: flex;
    justify-content: center;
}

.TitleContact {
    font-family: "Hello Almeida";
    color: #37646b;
    text-align: center;
    font-size: 45px;
    margin: auto;
    padding-top: 20px;
}

.Form {
    display: flex;
    flex-direction: column;
} 

.FormBoxName {
    font-family: "Google Sans", sans-serif;
    color: #37646b;
}

.InputGroup {
    margin: 20px;
}

.FormInput {
    width: 310px;
    max-width: 310px;
    height: 30px;
    font-size: 14px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
}

.RequiredBox {
    display: none;
}

.RequiredFormInput + .RequiredBox{
    color: red;
    display: block;
    font-size: 14px;
}

.TextArea {
    width: 310px;
    max-width: 350px;
    min-height: 100px;
    height: 100px;
    max-height: 300px;
    font-size: 14px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    resize: none;
}

.SendContact {
    font-family: "Google Sans", sans-serif;
    color: white;
    font-size: 18px;
    width: 5em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 30px;
    border-style: none;
    background-color: #37646b;
}

.SendContact:active {
    background: #37646b;
}

.SocialMedia {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    top: 30px;
    padding: 0 25%;
}

.SocialMedia > a {
    background-color: #4DB7BB;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: relative;
    display: flex;
    padding: 10px;
}

.SocialMedia img { 
    border: none;
}