.NotificationContainer {
  position: fixed;
  display: flex;
  bottom: 10px;
  left: 0;
  width: 100%;
  z-index: 11;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  color: white;
}

.Notification{
  padding: 10px 5px;
  width: 75%;
  max-width: 400px;
  border-radius: 10px;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px #555;
  opacity: 0.95;
}

.NotificationContent h2{
  margin: 0;
  font-size: 16px;
}	

.NotificationSuccess{
  background-color: #2ebb87;
}

.NotificationError{
  background-color: #e74c3c;
}

.NotificationWarning{
  background-color: #f1c40f;
}

.NotificationInfo{
  background-color: #3498db;
}