.Cart {
  width: 40px;
  height: 40px;
  position: relative;
  background-size: cover;
  background-color: #37646b;
}

.CartButton {
  width: 100%;
  height: 100%;
}

.CartView {
  right: 0;
  top: 55px;
  z-index: 5;
  width: 90vw;
  display: none;
  overflow: auto;
  color: black;
  max-width: 600px;
  max-height: calc(100vh - 100px);
  position: absolute;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 11px 18px -6px black;
}

.FloatingCart {
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 100%;
  box-shadow: black 0 0 10px 2px;
  width: 50px;
  height: 50px;
}

.FloatingCart img{
  opacity: 0.9;
}

.FloatingCart .Badge {
  top: 14px;
  left: 19px;
}

.Badge {
  top: 9px;
  left: 17px;
  padding: 0;
  width: 14px;
  height: 14px;
  color: white;
  font-size: 12px;
  border-radius: 100%;
  background-color: red;
}

.FinalCart {
  margin: 15px;
  font-size: 28px;
  color: #7d524b;
  display: flex;
  justify-content: space-evenly;

}

.CheckoutButton {
  font-family: "Google Sans", sans-serif;
  color: white;
  font-size: 15px;
  width: 140px;
  height: 2em;
  border-radius: 30px;
  border-style: none;
  background-color: #37646b;
}