.CheckoutContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.CartItemContainer {
  display: flex;
  flex-direction: column;
}

.CheckoutButton {
  margin: 20px auto;
  border: none;
  padding: 5px 20px;
  background-color: #37646b;
  border-radius: 20px;
  color: white
}

.CheckoutContainer .Observations {
  width: 80%;
  min-width: 200px;
  max-width: 720px;
  max-height: 300px;
  min-height: 60px;
  margin: 20px auto 0;
  outline: none;
  padding: 5px 10px;
}
