.TextField {
  display: flex;
  flex-direction: column;
}

.Input {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}

.TextArea {
  width: 100%;
  max-width: 310px;
  height: 30px;
  font-size: 14px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}

.Label {
  font-family: "Google Sans", sans-serif;
  color: #37646b;
}

.RequiredText {
  color: red;
  display: block;
  font-size: 14px;
}