.App {
  margin: 0;
  padding: 0;
  position: relative;
}

@font-face {
  font-family: "Hello Almeida";
  src: url('/public/assets/fonts/Hello\ Almeida.ttf');
}

/* Overwrite carousel styles */
.carousel .thumbs-wrapper.axis-vertical {
  margin: 0
}

ul.thumbs {
  padding: 0;
  display: none;
}