.Container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-width: 120px;
  max-width: 320px;
  text-align: left;
  position: relative;
  aspect-ratio: 8/13;
  border-radius: 20px;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;
}

.ImageContainer {
  aspect-ratio: 1;
  position: relative;
}

.ProductImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  position: relative;
}

.ProductTitle {
  bottom: 0;
  width: 100%;
  color: white;
  font-size: 22px;
  position: absolute;
  padding: 10px 0 10px 20px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: black 0px 0px 12px 4px;
}

.DescriptionContainer {
  padding: 10px 5px 0 20px;
  font-size: 100%;
  font-weight: 600;
  color: #333;
}


.PriceContainer {
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: space-evenly;
}

.Cart {
  width: 45px;
  height: 45px;
  background-size: contain;
  background-image: url('/public/assets/images/cart-add.png');
}

.PriceContainer span {
  font-size: 25px;
  color: #7d524b
}

.PriceContainer .PriceValue {
  font-size: 40px;
}


@media all and (max-width: 660px) {
  .Container {
    max-width: calc(50% - 10px);
  }
}

@media all and (max-width: 480px) {

  .ProductTitle {
    padding: 5px 10px;
    font-size: 2ex;
  }

  .DescriptionContainer {
    font-size: 1.3ex;
    padding: 0 8px;
  }

  .PriceContainer {
    padding-bottom: 5px;
  }

  .PriceContainer span {
    font-size: 125%;
  }

  .PriceContainer .PriceValue {
    font-size: 175%;
  }

  .Cart {
    width: 30px;
    height: 30px;
  }
}

@media all and (max-width: 320px) {
  .DescriptionContainer {
    font-size: 1ex;
  }
}


.ShakeCart {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}